// Logo

<template>
  <div v-if="logo">
    <div class="logo">
      <img class="effect1" :src="imagePath" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'logo',
  props: ['data'],
  computed: {
    model() {
      return this.$store.state;
    },
    imagePath() {
      return `${process.env.VUE_APP_BASE_CDN_URL}/${this.logo}`;
    },
    logo() {
      return this.model.logo;
    }
  }
};
</script>

<style lang="scss" scoped></style>
